<template>
    <div class="mt-3 stage-inner-container-padded">
        <div v-if="loading">
            <b-row v-if="!error">
                <b-col cols="12">
                    <h3>Processing... Hang In There, This Should Only Take A Few Moments</h3>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    <h3>Error</h3>
                </b-col>
                <b-col cols="12">
                    <h4>{{errorMessage}}</h4>
                </b-col>
            </b-row>
        </div>
        <div v-else-if="processingCompleted">
            <b-row>
                <b-col cols="12">
                    <h3>{{sessionTypeCap}} {{sessionTableCap}} Result:</h3>
                </b-col>
                <b-col cols="12">
                    <h3>CSV with parsed results and final IDs</h3>
                    <b-button variant="success" @click="downloadResultCSV">Download CSV</b-button>
                </b-col>
                <b-col cols="12">
                    <h4>Affected {{affectCount}} {{sessionTableCap}}</h4>
                </b-col>
            </b-row>
            <hr>
            <b-row v-for="(id, index) in affectIDs" :key="index" class="border-bottom my-0 py-1">
                <b-col cols="12">
                    <span>{{id}}</span> <span><b-link v-b-popover.hover.left="`View This ${sessionTableSingular} (Opens In New Tab)`" target="_blank" :to="getFormLink(sessionTableNoCap, id)">View This {{sessionTableSingular}} (Opens In New Tab)</b-link></span>
                </b-col>
            </b-row>
        </div>
        <div v-else>
            <b-row>
                <b-col cols="12">
                    <h3>Create or Update Records by CSV - Step 3 - Issues and Confirmation</h3>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col v-if="failures.length > 0" cols="12">
                    <h4>Critial Issues</h4>
                    <h5>These prevent the import/update process from proceeding.</h5>
                </b-col>
                <b-col v-else cols="12">
                    <h4>Hurray! No Critial Issues Were Found In Your Data Set</h4>
                </b-col>
            </b-row>
            <b-row v-for="(failure, index) in failures" :key="index">
                <b-col cols="12">
                    <span class="font-weight-bold">{{failure}}</span>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col v-if="warnings.length > 0" cols="12">
                    <h4>Non-Critial Issues</h4>
                    <h5>You can proceed without fixing these, but you should probably look them over.</h5>
                </b-col>
                <b-col v-else cols="12">
                    <h4>No Non-Critial Issues Were Found In Your Data Set</h4>
                </b-col>
            </b-row>
            <b-row v-for="(warn, index) in warnings" :key="index">
                <b-col cols="12">
                    <span class="font-weight-bold">{{warn}}</span>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12">
                    <b-button-toolbar justify>
                        <b-button variant="warning" @click="$router.go(-1)">Back</b-button>
                        <b-button variant="success" v-if="!processing" :disabled="!selectionValid" @click="proceed">{{sessionTypeCap}} Records</b-button>
                        <b-button variant="success" v-else disabled><b-spinner label="Spinning"></b-spinner></b-button>
                    </b-button-toolbar>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');
var fileDownload = require('js-file-download');

export default {
    name: 'BulkInsertUpdateIssues',
    components:{
    },
    props:{
        sessionID: String
    },
    data(){
        return{
            loading: true,
            error: false,
            errorMessage: null,

            processing: false,
            processingCompleted: false,

            session: {},
            sessionTableNoCap: null,
            sessionTypeCap: null,
            sessionTableCap: null,
            sessionTableSingular: null,
            affectCount: 0,
            affectIDs: [],
            
            resultCSVFileID: null,
            
            warnings: [],
            failures: []
        }
    },
    methods:{
        getFormLink(type, primID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: type});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + primID
            }
        },
        proceed(){
            if(!this.selectionValid){
                butils.createToast(this, "You cannot perform that action", "That action is not available because there are unresolved issues with the data you are attempting to import.", "warning")
            }else{
                this.processing = true;
                // Actually Request That The Records Are Imported
                butils.customInstance.timeoutLength(30000).get(process.env.VUE_APP_API_BASE_URL + `/bulk/session/import/${this.sessionID}`)
                .then((response)=>{
                    console.log(response.data.result);
                    this.affectCount = response.data.result.affectCount;
                    this.affectIDs = response.data.result.created;
                    this.createdResultFileID = response.data.result.createdResultFileID;
                    this.processing = false;
                    this.processingCompleted = true;
                })
                .catch((err)=>{
                    this.error = true;
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                        this.errorMessage = "Session Load Response 401 - Try Logging In Again";
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                        this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
                    }else if(butils.isError404(err)){
                        butils.createToast(this, 'Session Final Import Request Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                        this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
                    }else{
                        if(_.has(err, 'response')){
                            var resp = err.response;
                            this.errorMessage = resp.errors;
                        }else{
                            console.log(err);
                            this.errorMessage = "An Unexpected Error Occured Durring Session Request Final Import";
                            butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                        }
                    }
                })
            }
        },
        downloadResultCSV(){
            if(this.createdResultFileID == null){
                butils.createToast(this, "Cannot Download Result CSV", "No Result CSV Exists For This Set", "warning")
            }else{
                butils.customInstance.responseType.blob().get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + this.createdResultFileID)
                .then(async (response) => {
                    fileDownload(response.data, `Bulk Import Update Result ${this.sessionID}.csv`);
                })
                .catch((error) => {
                    console.log(error);
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    }else if(butils.isError404(err)){
                        butils.createToast(this, "Failed To Download Import/Update Result CSV", "File Does Not Exist", 'danger', 3600)
                    }else{
                        if(_.has(err, 'response')){
                            var resp = err.response;
                            this.errorMessage = resp.errors;
                            butils.createToast(this, "Failed To Download Import/Update Result CSV", "Unable to Download Import/Update Result CSV", 'danger', 3600)
                        }else{
                            butils.createToast(this, "Failed To Download Import/Update Result CSV", "Unable to Download Import/Update Result CSV", 'danger', 3600)
                        }
                    }
                });
            }
        }

    },
    watch:{

    },
    computed:{
        selectionValid: function(){
            // TRUE WHEN: No Errors, Load Completed, and No Failures
            return this.error == false && this.loading == false && this.failures.length == 0;
        }
    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        butils.customInstance.timeoutLength(30000).get(process.env.VUE_APP_API_BASE_URL + `/bulk/session/validate/${this.sessionID}`)
        .then((response)=>{
            console.log(response.data.result);
            this.session = response.data.result.session;
            this.sessionTableNoCap = this.session.against_table;
            this.sessionTableSingular = butils.formatters.capitalizeFirstLetter(butils.formatters.primaryTableLowerToSingular(this.session.against_table));
            this.sessionTypeCap = butils.formatters.capitalizeFirstLetter(this.session.type);
            this.sessionTableCap = butils.formatters.capitalizeFirstLetter(this.session.against_table);
            this.warnings = response.data.result.warnings;
            this.failures = response.data.result.failures;
            this.loading = false;
        })
        .catch((err)=>{
            this.error = true;
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                this.errorMessage = "Session Load Response 401 - Try Logging In Again";
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
            }else if(butils.isError404(err)){
                butils.createToast(this, 'Session Load Failed', 'The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.', 'danger');
                this.errorMessage = "The bulk import/update session you requested does not exist. It may have already been completed, was deleted by a user, or does not exist.";
            }else{
                if(_.has(err, 'response')){
                    var resp = err.response;
                    this.errorMessage = resp.errors;
                }else{
                    console.log(err);
                    this.errorMessage = "An Unexpected Error Occured Durring Session Load";
                    butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                }
            }
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>